import React, { useState, useEffect, useCallback, useRef } from "react";
import { saveAs } from "file-saver";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

function TableOfContents({
  items,
  onSelectItem,
  onDeleteItem,
  onUpdateFilteredItems,
}) {
  const [categories, setCategories] = useState({});
  const [selectionValues, setSelectionValues] = useState({});
  const [filter, setFilter] = useState(null);
  const [reviewFilter, setReviewFilter] = useState(null);
  const [selectionFilter, setSelectionFilter] = useState(null);
  const [filteredItems, setFilteredItems] = useState(items);
  const [isReviewedMode, setIsReviewedMode] = useState(false);
  const [isSelectedMode, setIsSelectedMode] = useState(false);
  const [reviewCounts, setReviewCounts] = useState({ Yes: 0, No: 0 });

  useEffect(() => {
    const categoryCount = items.reduce((acc, item) => {
      acc[item.category] = (acc[item.category] || 0) + 1;
      return acc;
    }, {});
    setCategories(categoryCount);

    const selectionCount = items.reduce(
      (acc, item) => {
        if (item.selection) {
          acc.Yes += 1;
        } else {
          acc.No += 1;
        }
        return acc;
      },
      { Yes: 0, No: 0 },
    );
    setReviewCounts(selectionCount);

    const selectionValuesCount = items.reduce((acc, item) => {
      if (item.selection) {
        acc[item.selection] = (acc[item.selection] || 0) + 1;
      } else {
        acc["None"] = (acc["None"] || 0) + 1;
      }
      return acc;
    }, {});
    setSelectionValues(selectionValuesCount);
  }, [items]);

  useEffect(() => {
    let updatedItems = items;

    if (isReviewedMode) {
      if (reviewFilter === "Yes") {
        updatedItems = items.filter((item) => item.selection);
      } else if (reviewFilter === "No") {
        updatedItems = items.filter((item) => !item.selection);
      }
    } else if (filter) {
      updatedItems = items.filter((item) => item.category === filter);
    } else if (selectionFilter) {
      if (selectionFilter === "None") {
        updatedItems = items.filter((item) => !item.selection);
      } else {
        updatedItems = items.filter(
          (item) => item.selection === selectionFilter,
        );
      }
    }

    setFilteredItems(updatedItems);
    onUpdateFilteredItems(updatedItems);
  }, [
    filter,
    reviewFilter,
    selectionFilter,
    items,
    isReviewedMode,
    onUpdateFilteredItems,
  ]);

  const toggleFilter = (category) => {
    if (filter === category) {
      setFilter(null);
    } else {
      setFilter(category);
    }
  };

  const toggleReviewFilter = (status) => {
    if (reviewFilter === status) {
      setReviewFilter(null);
    } else {
      setReviewFilter(status);
    }
  };

  const toggleSelectionFilter = (value) => {
    if (selectionFilter === value) {
      setSelectionFilter(null);
    } else {
      setSelectionFilter(value);
    }
  };

  const handleReviewedClick = () => {
    setIsReviewedMode(!isReviewedMode);
    setFilter(null); // Reset category filter when switching modes
    setSelectionFilter(null); // Reset selection filter when switching modes
    setIsSelectedMode(false);
  };

  return (
    <Container>
      <Typography variant="h4" sx={{ mb: 2, mt: 5, textAlign: "center" }}>
        Table of Contents
      </Typography>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6">Meta Data Field</Typography>
        <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
          <Button
            variant={isReviewedMode ? "contained" : "outlined"}
            onClick={handleReviewedClick}
          >
            Reviewed
          </Button>
          <Button
            variant={
              !isReviewedMode && !isSelectedMode ? "contained" : "outlined"
            }
            onClick={() => {
              setIsReviewedMode(false);
              setSelectionFilter(null);
              setReviewFilter(null);
              setIsSelectedMode(false);
            }}
          >
            Category
          </Button>
          <Button
            variant={isSelectedMode ? "contained" : "outlined"}
            onClick={() => {
              setIsReviewedMode(false);
              setReviewFilter(null);
              setFilter(null);
              setIsSelectedMode(true);
            }}
          >
            Selection
          </Button>
        </Box>
        <Typography variant="h6">Filters</Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          {isReviewedMode ? (
            <>
              <Button
                variant={reviewFilter === "Yes" ? "contained" : "outlined"}
                onClick={() => toggleReviewFilter("Yes")}
              >
                Yes
                <br />
                Count: {reviewCounts.Yes}
              </Button>
              <Button
                variant={reviewFilter === "No" ? "contained" : "outlined"}
                onClick={() => toggleReviewFilter("No")}
              >
                No
                <br />
                Count: {reviewCounts.No}
              </Button>
            </>
          ) : isSelectedMode ? (
            Object.keys(selectionValues).map((value) => (
              <Button
                variant={selectionFilter === value ? "contained" : "outlined"}
                key={value}
                onClick={() => toggleSelectionFilter(value)}
              >
                {value}
                <br />
                Count: {selectionValues[value]}
              </Button>
            ))
          ) : (
            Object.keys(categories).map((category) => (
              <Button
                variant={filter === category ? "contained" : "outlined"}
                key={category}
                onClick={() => toggleFilter(category)}
              >
                {category}
                <br />
                Count: {categories[category]}
              </Button>
            ))
          )}
        </Box>
      </Box>
      {filteredItems.map((item, index) => (
        <Card
          key={index}
          sx={{ mb: 2, border: "1px solid grey", position: "relative" }}
        >
          <CardActionArea onClick={() => onSelectItem(index)}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  position: "absolute",
                  top: 8,
                  left: 8,
                  right: 8,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "left",
                  }}
                >
                  <IconButton
                    aria-label="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteItem(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    aria-label="page-up"
                    onClick={(e) => {
                      e.stopPropagation();
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                    alignSelf="left"
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                </Box>
                {item.selection && (
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      backgroundColor: "green",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 4,
                }}
              >
                <Box sx={{ width: "70%" }}>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ mb: 1, color: "dark-grey" }}
                  >
                    <strong>Chat Summary:</strong> {item.chat_summary}
                  </Typography>
                </Box>
                <Box sx={{ width: "25%", marginLeft: "5%" }}>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ color: "dark-grey" }}
                  >
                    <strong>Category:</strong> {item.category}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ color: "dark-grey" }}
                  >
                    <strong>Selection:</strong> {item.selection}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </Container>
  );
}

function App() {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const output1Ref = useRef(null);
  const output2Ref = useRef(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const jsonData = JSON.parse(e.target.result);
        setItems(jsonData);
      };
      reader.readAsText(file);
    }
  };

  const handleNext = useCallback(() => {
    if (currentIndex < filteredItems.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  }, [currentIndex, filteredItems.length]);

  const handlePrevious = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  }, [currentIndex]);

  const handleSelection = (selection) => {
    const updatedItems = [...items];
    const itemIndex = items.findIndex(
      (item) => item === filteredItems[currentIndex],
    );
    updatedItems[itemIndex].selection = selection;
    setItems(updatedItems);
  };

  const handleDeleteItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const exportToJson = () => {
    const blob = new Blob([JSON.stringify(items, null, 2)], {
      type: "text/json;charset=utf-8",
    });
    saveAs(blob, "updated-results.json");
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowLeft":
          handlePrevious();
          break;
        case "ArrowRight":
          handleNext();
          break;
        default:
          break;
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleNext, handlePrevious]);

  const openEditor = (index) => {
    setCurrentIndex(index);
    setIsEditorOpen(true);
  };

  const closeEditor = () => {
    setIsEditorOpen(false);
  };

  const updateFilteredItems = useCallback((updatedItems) => {
    setFilteredItems(updatedItems);
  }, []);

  return (
    <Container>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 2, mb: 2 }}
      >
        <Grid item>
          <label htmlFor="raised-button-file">
            <Input
              id="raised-button-file"
              type="file"
              onChange={handleFileUpload}
              inputProps={{ accept: ".json" }}
              style={{ display: "none" }}
            />
            <Button variant="contained" component="span">
              Upload JSON
            </Button>
          </label>
        </Grid>
        {items.length > 0 && (
          <Grid item>
            <Button variant="contained" onClick={exportToJson}>
              Export JSON
            </Button>
          </Grid>
        )}
      </Grid>

      {items.length > 0 && (
        <TableOfContents
          items={items}
          onSelectItem={openEditor}
          onDeleteItem={handleDeleteItem}
          onUpdateFilteredItems={updateFilteredItems}
        />
      )}

      <Dialog open={isEditorOpen} onClose={closeEditor} maxWidth="xl" fullWidth>
        <DialogTitle>Review</DialogTitle>
        <DialogContent>
          {filteredItems.length > 0 && (
            <Box sx={{ mb: 2, mt: 5 }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ mb: 2 }}
              >
                <TextField
                  label="Category"
                  variant="outlined"
                  value={filteredItems[currentIndex].category || ""}
                  InputProps={{ readOnly: true }}
                  sx={{ width: "30%" }}
                />
              </Box>
              <TextField
                label="Chat Summary"
                variant="outlined"
                fullWidth
                multiline
                maxRows={20}
                value={filteredItems[currentIndex].chat_summary || ""}
                InputProps={{ readOnly: true, style: { overflowY: "hidden" } }}
                sx={{ mb: 2 }}
              />
              <Typography
                variant="h4"
                sx={{ mb: 2, mt: 5, textAlign: "center" }}
              >
                Side-by-Side Review
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Output 1"
                    variant="outlined"
                    fullWidth
                    value={filteredItems[currentIndex].output_1 || ""}
                    multiline
                    maxRows={20}
                    InputProps={{
                      readOnly: true,
                      style: {
                        overflowY: "hidden",
                        alignItems: "flex-start",
                      },
                    }}
                    inputRef={output1Ref}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Output 2"
                    variant="outlined"
                    fullWidth
                    value={filteredItems[currentIndex].output_2 || ""}
                    multiline
                    maxRows={20}
                    InputProps={{
                      readOnly: true,
                      style: {
                        overflowY: "hidden",
                        alignItems: "flex-start",
                      },
                    }}
                    inputRef={output2Ref}
                  />
                </Grid>
              </Grid>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ mb: 2 }}
              >
                <TextField
                  label="Selection"
                  variant="outlined"
                  fullWidth
                  value={filteredItems[currentIndex].selection || ""}
                  InputProps={{ readOnly: true }}
                  sx={{ width: "30%", mt: 2 }}
                />
              </Box>
              <Typography
                display="flex"
                justifyContent="center"
                alignItems="center"
                variant="h5"
                sx={{ mb: 2, mt: 5 }}
              >
                Preference Selection
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 2 }}
              >
                <Button
                  onClick={() => handleSelection("Output 1")}
                  variant="contained"
                  sx={{ mr: 1 }}
                >
                  Output 1
                </Button>
                <Button
                  onClick={() => handleSelection("Output 2")}
                  variant="contained"
                >
                  Output 2
                </Button>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 2 }}
              >
                <Button
                  onClick={() => handleSelection("Both Fails")}
                  variant="contained"
                  sx={{ mr: 1 }}
                >
                  Both Fails
                </Button>
                <Button
                  onClick={() => handleSelection("Both Passes")}
                  variant="contained"
                >
                  Both Passes
                </Button>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 2 }}
              >
                <Button
                  onClick={handlePrevious}
                  variant="contained"
                  sx={{ mr: 1 }}
                  disabled={currentIndex === 0}
                >
                  Previous
                </Button>
                <Button
                  onClick={handleNext}
                  variant="contained"
                  sx={{ mr: 1 }}
                  disabled={currentIndex === filteredItems.length - 1}
                >
                  Next
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default App;
